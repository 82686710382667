@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  -webkit-tap-highlight-color: transparent;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  padding: 20px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-table-row .ant-table-row-level-0 tr {
  background-color: #1890ff;
}

/* .table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f5f5;
} */

tbody tr:hover td {
  cursor: pointer;
  background-color: #dfdfdf !important;
}
tbody tr td {
  margin: 0 !important;
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7f7f7;
  margin-top: 1px;
  margin-bottom: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(230, 230, 230);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(211, 211, 211);
}
::-webkit-scrollbar-button {
  width: 50px;
  height: 50px;
}

.teste {
  background-color: brown;
}
.ant-table.ant-table-small .ant-table-cell {
  padding: 2px 2px !important;
}

